/**
 * Stores used during company add/edit
 */
import { defineStore } from 'pinia'
import getFormConfigStore from './formConfig'
import { collectionHelpers } from './modelStore'
import api from '@/api/index'
export const useCompanyConfigStore = getFormConfigStore('company')

// Fields loaded by default via API call
const defaultFields = [
    'goal',
    'name',
    'activities',
    'email',
    'phone',
    'mobile',
    'address',
    'zip_code',
    'city',
    'country',
    'latitude',
    'longitude',
    'logo',
    'logo_id',
    'cgv',
    'header',
    'header_id',
    'decimal_to_display',
    'internal',

    'code_compta',

    'general_customer_account',
    'general_expense_account',

    'third_party_customer_account',

    'general_supplier_account',
    'third_party_supplier_account',

    'internalgeneral_customer_account',
    'internalthird_party_customer_account',

    'internalgeneral_supplier_account',
    'internalthird_party_supplier_account',

    'contribution',
    'internalcontribution',

    'insurance',
    'internalinsurance',

    'RIB',
    'IBAN',

    'antenne_id',
    'follower_id',

    'use_margin_rate_in_catalog',
    'margin_rate'
  ]
export const useCompanyStore = defineStore('company', {
  state: () => ({
    loading: true,
    error: false,
    collection: [],
    collectionMetaData: {},
    companyId: null,
    item: {},
  }),
  actions: {
    setCurrentCompanyId(companyId) {
      this.companyId = companyId
    },
    async loadCompanies(companyId, options) {
      this.loading = true
      return api.companies
        .getCompanies(companyId, options)
        .then((result) => {
          let collection
          if (options.pageOptions && result.length == 2) {
            this.collectionMetaData = result[0]
            collection = result[1]
          } else {
            collection = result
          }
          this.loading = false
          this.error = ''
          this.collection = collection
          return collection
        })
        .catch(this.handleError)
    },
    async loadCompany(companyId = null, fields = null) {
      if (fields === null) {
        fields = defaultFields
      }
      companyId = companyId || this.companyId
      if (!companyId) {
        throw Error('no Id provided')
      }
      this.loading = true
      return api.companies
        .load(companyId, fields)
        .then((item) => {
          this.loading = false
          this.error = false
          this.item = item
          return item
        })
        .catch(this.handleError)
    },
    async handleError(error) {
      this.loading = false
      this.error = error
      return Promise.reject(error)
    },
    async saveCompany(data) {
      let companyId = this.companyId || data.id
      if (companyId) {
        return api.companies.update(data, companyId)
      } else {
        return this.createCompany(data)
      }
    },
    async createCompany(data) {
      if (data.id) {
        throw Error('Company already exists (has an id)')
      }
      return api.companies.create(data)
    }
  },
  getters: collectionHelpers('company'),
})
